'use strict';

// object-fit polyfill
import objectFitImages from 'object-fit-images';
objectFitImages();

// AOS
// import AOS from 'aos';
// document.addEventListener("DOMContentLoaded", () => {
//     AOS.init();
// });

import './_fancyapps'
import './_swiper'
import './_main'
import './_components'