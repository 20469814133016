document.addEventListener("DOMContentLoaded", () => {
    const scrollProductsWrap = document.querySelector('.js-scroll-products');
    const scrollProductsNext = document.querySelector('.js-scroll-products-next');
    const scrollProductsPrev = document.querySelector('.js-scroll-products-prev');

    if (scrollProductsWrap !== null) {
        if (scrollProductsPrev) {
            scrollProductsPrev.addEventListener('click', () => {
                scrollProductsWrap.scrollLeft -= 200;
            });
        }
        if (scrollProductsNext) {
            scrollProductsNext.addEventListener('click', () => {
                scrollProductsWrap.scrollLeft += 200;
            });
        }
    }
});