document.addEventListener("DOMContentLoaded", () => {
    const sliderName = 'about-list';
    const sliderSetings = {
        init: true,
        loop: false,
        autoplay: false,
        centeredSlides: false,
        effect: 'slide',
        freeMode: false,
        longSwipes: false,
        loopedSlides: 0,
        navigation: false,
        pagination: false,
        pauseOnMouseEnter: true,
        preloadImages: true,
        roundLengths: true,
        speed: 800,
        slidesPerView: 1,
        spaceBetween: 20,
        containerModifierClass: sliderName + '_',
        wrapperClass: sliderName + '__wrapper',
        slideClass: sliderName + '__item',
        slideActiveClass: sliderName + '__item_active',
        slideDuplicateActiveClass: sliderName + '__item_duplicate_active',
        slideVisibleClass: sliderName + '__item_visible',
        slideDuplicateClass: sliderName + '__item_duplicate',
        breakpoints: {
            [window.adaptive.SM]: {
                slidesPerView: 1,
                spaceBetween: 24
            },
            [window.adaptive.MD]: {
                slidesPerView: 1,
                spaceBetween: 24
            },
            [window.adaptive.LG]: {
                slidesPerView: 2,
                spaceBetween: 24
            },
            [window.adaptive.XL]: {
                slidesPerView: 2,
                spaceBetween: 36
            },
            [window.adaptive.XXL]: {
                slidesPerView: 2,
                spaceBetween: 40
            },
        }
    };

    if (document.querySelector('.js-' + sliderName) !== null) {
        let aboutSlider;

        const breakpoint = window.matchMedia('(max-width: 767.98px)');

        const breakpointChecker = function () {
            if (aboutSlider !== undefined) aboutSlider.destroy(true, true);
            if (breakpoint.matches === true) {
                aboutSlider = window.slider('.js-' + sliderName, sliderSetings);
                aboutSlider.init();
            }
        };

        breakpoint.addListener(breakpointChecker);

        breakpointChecker();
    }
});