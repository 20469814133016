document.addEventListener("DOMContentLoaded", () => {
    const sliderName = 'products-love-is';
    const sliderSetings = {
        init: true,
        loop: false,
        autoplay: false,
        centeredSlides: false,
        effect: 'slide',
        freeMode: false,
        longSwipes: false,
        loopedSlides: 0,
        navigation: false,
        pagination: false,
        pauseOnMouseEnter: true,
        preloadImages: true,
        roundLengths: true,
        scrollbar: {
            el: '.' + sliderName + '__scrollbar',
            hide: false,
            draggable: true,
            dragClass: sliderName + '__scrollbar-drag',
            scrollbarDisabledClass: sliderName + '__scrollbar_disabled',
            lockClass: sliderName + '__scrollbar_lock',
            horizontalClass: sliderName + '__scrollbar_horizontal',
            verticalClass: sliderName + '__scrollbar_vertical',
        },
        speed: 800,
        slidesPerView: 2,
        spaceBetween: 30,
        containerModifierClass: sliderName + '_',
        wrapperClass: sliderName + '__wrapper',
        slideClass: sliderName + '__item',
        slideActiveClass: sliderName + '__item_active',
        slideDuplicateActiveClass: sliderName + '__item_duplicate_active',
        slideVisibleClass: sliderName + '__item_visible',
        slideDuplicateClass: sliderName + '__item_duplicate',
        breakpoints: {
            [window.adaptive.SM]: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            [window.adaptive.MD]: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
            [window.adaptive.LG]: {
                slidesPerView: 4,
                spaceBetween: 40,
            },
            [window.adaptive.XL]: {
                slidesPerView: 5,
                spaceBetween: 40,
            },
            [window.adaptive.XXL]: {
                slidesPerView: 5,
                spaceBetween: 40,
            },
        }
    };

    window.slider('.js-' + sliderName, sliderSetings);
});