document.addEventListener("DOMContentLoaded", () => {
    const sliderThumbsName = 'product-thumbs-slider';
    const sliderThumbsSetings = {
        init: true,
        loop: false,
        autoplay: false,
        centeredSlides: false,
        effect: 'slide',
        freeMode: true,
        longSwipes: false,
        loopedSlides: 0,
        navigation: false,
        pagination: false,
        pauseOnMouseEnter: true,
        preloadImages: true,
        roundLengths: true,
        speed: 800,
        slidesPerView: 3,
        spaceBetween: 6,
        watchSlidesProgress: true,
        containerModifierClass: sliderThumbsName + '_',
        wrapperClass: sliderThumbsName + '__wrapper',
        slideClass: sliderThumbsName + '__item',
        slideActiveClass: sliderThumbsName + '__item_active',
        slideDuplicateActiveClass: sliderThumbsName + '__item_duplicate_active',
        slideVisibleClass: sliderThumbsName + '__item_visible',
        slideDuplicateClass: sliderThumbsName + '__item_duplicate',
        breakpoints: {
            [window.adaptive.SM]: {
                slidesPerView: 4,
                spaceBetween: 6,
            },
            [window.adaptive.MD]: {
                slidesPerView: 5,
                spaceBetween: 6,
            },
            [window.adaptive.LG]: {
                slidesPerView: 5,
                spaceBetween: 6,
            },
            [window.adaptive.XL]: {
                slidesPerView: 5,
                spaceBetween: 6,
            },
            [window.adaptive.XXL]: {
                slidesPerView: 5,
                spaceBetween: 6,
            },
        }
    };

    const productThumbsSlider = window.slider('.js-' + sliderThumbsName, sliderThumbsSetings);
    
    const sliderName = 'product-slider';
    const sliderSetings = {
        init: true,
        loop: false,
        autoplay: false,
        centeredSlides: false,
        effect: 'slide',
        freeMode: false,
        longSwipes: false,
        loopedSlides: 0,
        navigation: false,
        pagination: false,
        pauseOnMouseEnter: true,
        preloadImages: true,
        roundLengths: true,
        speed: 800,
        slidesPerView: 1,
        spaceBetween: 0,
        thumbs: {
            slideThumbActiveClass: '_current',
            multipleActiveThumbs: false,
            swiper: productThumbsSlider,
            thumbsContainerClass: sliderName + '__thumbs'
        },
        containerModifierClass: sliderName + '_',
        wrapperClass: sliderName + '__wrapper',
        slideClass: sliderName + '__item',
        slideActiveClass: sliderName + '__item_active',
        slideDuplicateActiveClass: sliderName + '__item_duplicate_active',
        slideVisibleClass: sliderName + '__item_visible',
        slideDuplicateClass: sliderName + '__item_duplicate',
        breakpoints: {
            [window.adaptive.SM]: {
                slidesPerView: 1,
            },
            [window.adaptive.MD]: {
                slidesPerView: 1,
            },
            [window.adaptive.LG]: {
                slidesPerView: 1,
            },
            [window.adaptive.XL]: {
                slidesPerView: 1,
            },
            [window.adaptive.XXL]: {
                slidesPerView: 1,
            },
        }
    };

    window.slider('.js-' + sliderName, sliderSetings);
});