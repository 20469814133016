'use strict';

import { Fancybox } from "@fancyapps/ui";

window.Fancybox = window.fancybox = Fancybox;

Fancybox.bind("[data-fancybox]", {
	// template: {
	// 	closeButton: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 4L4 12" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M4 4L12 12" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
	// }
});
